<template>
  <v-card width="60%" style="margin: 15px auto;" v-if="card">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Копирование карты режимов</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="dataValid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="ID/название/номер карты"
              v-model="card.version.name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6">
            <div>Согласующая сторона</div>
            <v-text-field
              label="ФИО"
              v-model="card.version.agreeder.name"
            />
            <v-text-field
              label="Организация"
              v-model="card.version.agreeder.org"
            />
            <v-text-field
              label="Должность"
              v-model="card.version.agreeder.post"
            />
            <v-text-field
              label="Дата"
              v-model="card.version.agreeder.date"
            />
          </v-col>
          <v-col cols="6">
            <div>Утверждающая сторона</div>
            <v-text-field
              label="ФИО"
              v-model="card.version.accepter.name"
            />
            <v-text-field
              label="Организация"
              v-model="card.version.accepter.org"
            />
            <v-text-field
              label="Должность"
              v-model="card.version.accepter.post"
            />
            <v-text-field
              label="Дата"
              v-model="card.version.accepter.date"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow
              filled
              rows="4"
              row-height="30"
              label="Краткое описание технологической карты"
              v-model="card.version.about"
            />
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="card.version.weldMethod"
              :items="weldMethodItems"
              label="Способ сварки"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
            >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="card.version.elementsType"
              :items="elementsTypeItems"
              label="Вид соединяемых элементов"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
            >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="card.version.seamType"
              :items="seamTypeItems"
              label="Тип шва"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
            >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="card.version.jointType"
              :items="jointTypeItems"
              label="Тип соединения"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
            >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <div class="text-center">
          Характеристика свариваемых элементов
        </div>
        <v-row>
          <v-col cols="6">
            <div class="text-center">
              Характеристика элемента №1
            </div>
            <v-text-field
              label="Марка материала элемента №1"
              :rules="[rules.required]"
              v-model="card.version.elem1Material"
            />
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              Характеристика элемента №2
            </div>
            <v-text-field
              label="Марка материала элемента №2"
              :rules="[rules.required]"
              v-model="card.version.elem2Material"
            />
          </v-col>
        </v-row>
          <v-row>
          <v-col cols="6" v-if="card.version.elementsType !== 'Л+Л' && card.version.elementsType.length > 0">
            <v-text-field
              v-model="card.version.d1"
              label="Диаметр элемента 1, мм (D1)"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" v-if="card.version.elementsType !== 'Л+Л' && card.version.elementsType !== 'Т+Л' && card.version.elementsType !== 'С+Л' && card.version.elementsType.length > 0">
              <v-text-field
                v-model="card.version.d2"
                label="Диаметр элемента 2, мм (D2)"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="6" v-if="card.version.elementsType !== 'С+С' && card.version.elementsType.length > 0">
            <v-text-field
              v-model="card.version.s1"
              label="Толщина элемента 1, мм (S1)"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" v-if="card.version.elementsType !== 'С+С' && card.version.elementsType !== 'С+Л' && card.version.elementsType !== 'С+Т' && card.version.elementsType.length > 0">
            <v-text-field
              v-model="card.version.s2"
              label="Толщина элемента 2, мм (S2)"
              :rules="[rules.required]"
            />
          </v-col>
          </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="card.version.weldMaterial"
              label="Введите марку сварочного материала"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="card.version.weldPosition"
              :items="weldPositionItems"
              label="Положение в пространстве"
              :rules="[rules.required]"
              item-value="text"
              :return-object="false"
              >
              <template v-slot:item="{ index, item, parent, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.text }}</v-list-item>
                  </template>
                  <span>{{item.tooltip}}</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12">
            <v-textarea
              label="Прочие параметры"
              v-model="card.version.others"
            />
          </v-col>
          <v-col cols="12">
            <Files
              :value="card.version.files"
              @input="addFile"
              @removeFile="removeFile"
            />
          </v-col>
          <v-col cols="12">
            <Layers v-model="card.version.layers"></Layers>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="copy" :loading="loading" :disabled="loading||!dataValid">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
import Files from './components/Files.vue'
import Layers from './components/Layers.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Files,
    Layers
  },
  data: () => ({
    rules: rules,
    dataValid: true,
    loading: false,
    elementsType: null,
    card: null,
    // {
    //   orgId: '',
    //   name: '' ,
    //   weldMethod: '',
    //   elementsType: '',
    //   d1: '',
    //   d2: '',
    //   s1: '',
    //   s2: '',
    //   seamType: '',
    //   jointType: '',
    //   baseMaterial: '',
    //   weldMaterial: '',
    //   weldPosition: '',
    //   others: '',
    //   files: [],
    //   layers: [],
    // },
    weldMethodItems: [
      { text: 'РД(111)', tooltip: 'Ручная дуговая сварка покрытыми электродами'},
      { text: 'РАД(141)', tooltip: 'Ручная адгонодуговая сварка неплавящимся электродом'},
      { text: 'МП(135)', tooltip: 'Механизированная сварка плавящимся элетродом в среде активных газов и смесях'},
      { text: 'МАДП(131)', tooltip: 'Механизированная аргонодуговая сварка плавящимся элетродом'},
      { text: 'МПГ(136)', tooltip: 'Механизированная сварка порошковой проволокой в среде активных газов и смесях'},
      { text: 'МПИ(137)', tooltip: 'Механизированная сварка порошковой проволокой в среде инертных газов и смесях'},
      { text: 'МПС(114)', tooltip: 'Механизированная сварка самозащитной порошковой проволокой'},
      { text: 'АФ(12)', tooltip: 'Автоматическая сварка под флюсом'},
      { text: 'ААД(141)', tooltip: 'Автоматическая аргонодуговая сварка неплавящимся элетродом'},
      { text: 'ААДП(131)', tooltip: 'Автоматическая аргонодуговая сварка плавящимся элетродом'},
      { text: 'АПГ(135)', tooltip: 'Автоматическая сварка плавящимся элетродом в среде активных газов и смесях'},
      { text: 'АППГ(136)', tooltip: 'Автоматическая сварка порошковой проволокой в среде активных газов и смесях'},
      { text: 'АПИ(137)', tooltip: 'Автоматическая сварка порошковой проволокой в среде инертных газов и смесях'},
      { text: 'АПС(114)', tooltip: 'Автоматическая сварка самозащитной порошковой проволокой'},
    ],
    elementsTypeItems: [
      { text: 'Т+Т', tooltip: 'Соединение трубы с трубой'},
      { text: 'Л+Л', tooltip: 'Соединение листа с листом (листовым элементом считается любой элемент не имеющий радиуса кривизны в зоне сварного соединения'},
      { text: 'Т+Л', tooltip: 'Соединение трубы с листовым элементом'},
      { text: 'С+С', tooltip: 'Соединение стержня со стрержнем (арматуры с арматурой)'},
      { text: 'С+Л', tooltip: 'Соединение стержня с листовым элементом'},
      { text: 'С+Т', tooltip: 'Соединение стержня с трубой'}
    ],
    jointTypeItems: [
      { text: 'С', tooltip: 'Стыковое соединение' },
      { text: 'У', tooltip: 'Угловое соединение' },
      { text: 'Т', tooltip: 'Тавровое соединение' },
      { text: 'Н', tooltip: 'Нахлесточное соединение' },
    ],
    seamTypeItems: [
      { text: 'СШ', tooltip: 'Стыковой шов' },
      { text: 'УШ', tooltip: 'Угловой шов' },
      { text: 'ТШ', tooltip: 'Точечный шов' },
    ],
    weldPositionItems: [
      { text: 'Н1', tooltip: 'Нижнее стыковое и в "лодочку", а также стыковые и нахлесточные соединения стержней и тавровые соединения стержней с листами, выполняемые в нижнем положении со стороны привариваемого стержня' },
      { text: 'Н2', tooltip: 'Нижнее тавровое, а также крестообразные соединения стержней и нахлесточные соединения стержней с листами, выполняемые в нижнем положении' },
      { text: 'Г', tooltip: 'Горизонтальное' },
      { text: 'П1', tooltip: 'Потолочное стыковое, а также нахлесточные соединения стержней, выполняемые в потолочном положении' },
      { text: 'П2', tooltip: 'Потолочное тавровое, а также крестообразные соединения стержней и нахлесточные соединения стержней с листами, выполняемые в потолочном положении' },
      { text: 'В1', tooltip: 'Вертикальное снизу вверх' },
      { text: 'В2',tooltip: 'Вертикальное сверху вниз' },
      { text: 'Н45',tooltip: 'Наклоненное под углом 45' },
    ],
  }),
    computed: {
      ...mapState('organization', {
        orgId: state => state.profile.id,
      })
    },
    methods: {
      removeFile(e) {
        if (e.new) {
          this.axios.post('file/delete', { url: e.url }).then(() => {
            let index = this.card.version.files.findIndex(f => f.name == e.name)
            this.card.version.files.splice(index, 1)
          })
        } else {
          let file = this.card.version.files.find(f => f.name == e.name)
          this.axios.post('simple-card/card/delete-file', {
            versionId: file.cardVersionId,
            fileId: file.id
          }).then(() => {
            let index = this.card.version.files.findIndex(f => f.name == e.name)
            this.card.version.files.splice(index, 1)
          })
        }
      },
      addFile(file) {
        this.card.version.files.push(file)
      },
      copy() {
        this.loading = true
        this.$axios.post('/simple-card/card/copy', {
          ...this.card.version,
        }, {
          params: { id: this.card.id }
        }).then(res => {
          this.$router.push('/simple-card/card/index')
        }).catch(() => {}).finally(() => {
          this.loading = false
        })
      }
    },
    mounted: function() {
      axios.get('/simple-card/card/view?id=' + this.$route.query.id).then(r => {
        this.card = r.data
        // this.card.version.name = ''
        // this.card.version.id = null
      })
    }
}
</script>
